import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import { graphql } from "gatsby"
import SingleTemplate from "../components/Sections/SingleTemplate"

export const query = graphql`
  query($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      title
      slug
      keywords
      metaDescription
      content {
        raw
      }
      picture {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        title
        file {
          fileName
          url
        }
      }
      panoramaPicture {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        file {
          fileName
          url
        }
      }
      shortDescription {
        shortDescription
      }
    }
  }
`

const CaseStudyTemplate = props => {
  return (
    <Layout>
      <Head
        description={props.data.contentfulCaseStudy.metaDescription}
        pageTitle={props.data.contentfulCaseStudy.title}
        keywords={props.data.contentfulCaseStudy.keywords}
      />
      <SingleTemplate
        pageData={props.data.contentfulCaseStudy}
        pageContext={props.pageContext}
        prefixUrl="case-studies"
      />
    </Layout>
  )
}

export default CaseStudyTemplate
